import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdminElsMunicipalityService} from '../admin-els-municipality.service';
import {AdminYearService} from '../admin-year.service';

@Component({
	selector: 'app-municipality-compare',
	templateUrl: './municipality-compare.component.html',
	styleUrls: ['./municipality-compare.component.css']
})
export class MunicipalityCompareComponent implements OnInit {
	mun=[];
	domenSubdomenList:any[];
	els;
	lastYear;

	constructor(public route:ActivatedRoute, public serviceEls:AdminElsMunicipalityService, public serviceYear:AdminYearService) { 


	}

	ngOnInit() {
		var that=this;

		this.serviceYear.getLastYear().subscribe(a=>{
			a.forEach(function(b){
				that.lastYear=b.key;
			});


			this.route.paramMap.subscribe(id=>{
				var fullParametersId=[];
				var idParameter;
				fullParametersId=id.keys;
			/*fullParametersId.forEach(function(idIn){
				idParameter=id.get(idIn);
				that.serviceEls.getElsMunicipalityId(idParameter).subscribe(b=>{
					that.mun.push(b);	
					var newDomenSubdomen=[];
					for(var key in b.domenSubdomen) {
						var myTemp=[];
						for(var key1 in b.domenSubdomen[key].subdomens) {
							myTemp.push({subdomenName:b.domenSubdomen[key].subdomens[key1].subdomenName, subdomenValue:b.domenSubdomen[key].subdomens[key1].subdomenValue});
						}
						newDomenSubdomen.push({domenName:b.domenSubdomen[key].domenName, domenValue:b.domenSubdomen[key].domenValue, subdomeni:myTemp});
					};
					that.domenSubdomenList=newDomenSubdomen;

					b.domenSubdomen=newDomenSubdomen
				});
			});*/


			fullParametersId.forEach(function(idIn){
				idParameter=id.get(idIn);
				that.els=that.serviceEls.getElsMunicipalityId(idParameter, that.lastYear);
				
				that.els.forEach(function (b) {
					
					that.mun.push(b);	
					var newDomenSubdomen=[];
					for(var key in b.domenSubdomen) {
						var myTemp=[];
						
						for(var key1 in b.domenSubdomen[key].subdomens) {
							var myColumn=[];
							for(var col in b.domenSubdomen[key].subdomens[key1].columns){
								if(col=='1rank' || col=="2grade"){

									myColumn.push({columnName:b.domenSubdomen[key].subdomens[key1].columns[col].columnName, columnValue:b.domenSubdomen[key].subdomens[key1].columns[col].columnValue});
								}
							}
							myTemp.push({subdomenName:b.domenSubdomen[key].subdomens[key1].subdomenName, columns:myColumn});
						}
						var mydomenCol=[];

						for(var colDomen in b.domenSubdomen[key].columns){

							if(colDomen=='1rank' || colDomen=="2grade"){
								mydomenCol.push({columnName:b.domenSubdomen[key].columns[colDomen].columnName, columnValue:b.domenSubdomen[key].columns[colDomen].columnValue})
							}
							
						}
						newDomenSubdomen.push({domenName:b.domenSubdomen[key].domenName, subdomeni:myTemp, columns:mydomenCol});
					};
					that.domenSubdomenList=newDomenSubdomen;
				
					b.domenSubdomen=newDomenSubdomen;
					
				})
			})


			
		});
			
		});

	}
	showClass(){
		var poimnik = document.getElementById('mypoimnik');
		poimnik.classList.add('myclass');

		
	}
	showMap(){

		var poimnik = document.getElementById('mymap');
		poimnik.classList.add('myclass');
	}

	removeBox(){
		var poimnik = document.getElementById('mypoimnik');
		poimnik.classList.remove("myclass");
		var map = document.getElementById('mymap');
		map.classList.remove("myclass");

	}


}
