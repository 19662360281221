import { Component, OnInit } from '@angular/core';
import {AdminYearService} from '../admin-year.service';
import {AdminDomenService} from '../admin-domen.service';

@Component({
	selector: 'app-admin-year',
	templateUrl: './admin-year.component.html',
	styleUrls: ['./admin-year.component.css']
})
export class AdminYearComponent implements OnInit {
	arrayRemove=[];
	idYear;
	domeni;
	error;
	year;
	constructor(public service:AdminYearService, public serviceDomen:AdminDomenService) { }

	ngOnInit() {
		this.getDomen();
	}


	getCheckboxVal(event, key, name){

		if(event.target.checked==true){
			this.arrayRemove.push({key,name});
			console.log(this.arrayRemove);
  		//this.service.addSubdomenRealtion(key,name);
  		
  	}else{
  		var index=this.arrayRemove.indexOf(key);
  		this.arrayRemove.splice(index, 1);
  		//this.service.removeSubdomen(key);
  		
  	}
  }

  addYear(name){
  	var that=this;
  	var newArray = this.arrayRemove.reduce((map, obj) => (map[obj.key] = obj.name, map), {});
  		if(name!=undefined && this.arrayRemove.length!=0){
  	this.idYear=this.service.addYear(name, newArray);
  	this.error='';

  	}else{
  		this.error='Ве молиме пополнете ги полињата означени со *';
  	}
/*	this.arrayRemove.forEach(function (domenNew) {
	//that.service.addDomenYearRealtion(that.idYear, domenNew.key, domenNew.name);
	//that.service.addDomenYearRealtion(domenNew.key,that.idYear,name);
    
});*/
}
getDomen(){
	this.domeni=this.serviceDomen.getDomenList();
}

}
