import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { AngularFireDatabase ,AngularFireList} from '@angular/fire/database';
import {Routes, RouterModule, Router} from '@angular/router';

import {AdminYearService} from '../admin-year.service';
import {AdminDomenService} from './../admin-domen.service';
import {AdminSubdomenService} from './../admin-subdomen.service';

@Component({
	selector: 'app-admin-year-edit',
	templateUrl: './admin-year-edit.component.html',
	styleUrls: ['./admin-year-edit.component.css']
})
export class AdminYearEditComponent implements OnInit {
	idYear;
	year;
	yearData;
	domeni;
	domeniYear;
	oldDomeniArray=[];
	checkedValue=[];
	newDomenCheckbox=[];

	constructor(public route:ActivatedRoute, public service:AdminYearService, public serviceDomen:AdminDomenService) {


	}

	ngOnInit() {
		this. getDomen();
		var that=this;
		this.route.paramMap.subscribe(id=>{
			this.idYear=id.get('idYear');
		/*	this.yearData=this.service.getYear(this.idYear).subscribe(objectdata => 
			{
				this.yearData=objectdata;
				this.checkedValue=Object.keys(objectdata.domeni);
				this.oldDomeniArray.push(objectdata.domeni);
				this.oldDomeniArray.forEach(function (a) {
					that.newDomenCheckbox= Object.keys(a).map(key => {
						return {
							key: key,
							name: a[key]
						};
					});
				})

			});*/

			//old code



	this.yearData=this.service.getYear(this.idYear);
			this.yearData.forEach(function (a) {
				that.year=a.year;
				
				that.checkedValue=Object.keys(a.domeni);
				
				that.oldDomeniArray.push(a.domeni);
				that.oldDomeniArray.forEach(function (a) {
					that.newDomenCheckbox= Object.keys(a).map(key => {
						return {
							key: key,
							name: a[key]
						};
					});
				})
				// body...
			})



		});




	}

	getDomen(){
		this.domeni=this.serviceDomen.getDomenList();
	}


	getCheckboxVal(event, key, name){

		if(event.target.checked===true){
			this.newDomenCheckbox.push({key:key, name:name});
		}else{
			var index=this.newDomenCheckbox.findIndex(p => p.key == key);
			this.newDomenCheckbox.splice(index, 1);
		}

	}

	updateYear(key, newname){
		if(newname==undefined){
			newname=this.year;
		}
		var newObjectDomeni=this.newDomenCheckbox.reduce((map, obj) => (map[obj.key] = obj.name, map), {});
		this.service.updateYear(key, newname, newObjectDomeni);
		
	}


	



}
